import {
   addBreathingExerciseDtoSchema,
   addSwipePhotosDtoSchema,
   type BreathingExercisesResponseDto,
   type SwipePhotosResponseDto,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const games = c.router(
   {
      getBreathingExercises: {
         method: 'GET',
         path: '/games/breathing-exercises',
         responses: {
            200: c.type<BreathingExercisesResponseDto>(),
         },
      },
      addBreathingExercise: {
         method: 'POST',
         path: '/games/breathing-exercises',
         body: addBreathingExerciseDtoSchema,
         responses: {
            200: c.type<BreathingExercisesResponseDto>(),
         },
      },
      getSwipePhotos: {
         method: 'GET',
         path: '/games/swipe-photos',
         responses: {
            200: c.type<SwipePhotosResponseDto>(),
         },
      },
      addSwipePhotos: {
         method: 'POST',
         path: '/games/swipe-photos',
         body: addSwipePhotosDtoSchema,
         responses: {
            200: c.type<SwipePhotosResponseDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
