import {
   type MarketingSearchClinician,
   marketingSearchCliniciansSchema,
   type MarketingInitialDataResponse,
   type MarketingSearchCliniciansResponse,
   type MarketingCliniciansAvailabilityResponse,
   arrayOfNumbersField,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const marketing = c.router(
   {
      getInitialData: {
         method: 'GET',
         path: '/marketing/initial-data',
         responses: {
            200: c.type<MarketingInitialDataResponse>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      searchClinicians: {
         method: 'GET',
         path: '/marketing/search-clinicians',
         query: marketingSearchCliniciansSchema,
         responses: {
            200: c.type<MarketingSearchCliniciansResponse>(),
         },
      },
      getCliniciansAvailability: {
         method: 'GET',
         path: '/marketing/clinicians-availability',
         query: z.object({
            timetapIds: arrayOfNumbersField,
         }),
         responses: {
            200: c.type<MarketingCliniciansAvailabilityResponse>(),
         },
      },
      getClinician: {
         method: 'GET',
         path: '/marketing/clinician',
         query: z.object({
            welkinId: z.string(),
         }),
         responses: {
            200: c.type<MarketingSearchClinician>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
