import { initContract } from '@ts-rest/core';
import { type CdtContactConsent, type PatientCdtsItem } from '@innerwell/dtos';

const c = initContract();
export const contactConsent = c.router(
   {
      confirmConsent: {
         method: 'POST',
         path: '/contact-consent',
         body: c.noBody(),
         responses: {
            200: c.type<PatientCdtsItem<CdtContactConsent>>(),
         },
      },

      getConsent: {
         method: 'GET',
         path: '/contact-consent',
         responses: {
            200: c.type<PatientCdtsItem<CdtContactConsent> | undefined>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
