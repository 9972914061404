import {
   type EmergencyContactInfo,
   type MyPatientResponseDto,
   type PatientLastChosenCarePlanDto,
   type PatientBmiResponseDto,
   type PatientMedicationsResponseDto,
   type PatientParamsResponseDto,
   type ShippingLogDto,
   type SitterInfo,
   confirmDosageDtoSchema,
   confirmShippingDtoSchema,
   patchPatientDtoSchema,
   updateEmergencyContactDtoSchema,
   updatePatientSelectedPlanDtoSchema,
   updateSitterDtoSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const patients = c.router(
   {
      getSitter: {
         method: 'GET',
         path: '/patients/sitter',
         responses: {
            200: c.type<SitterInfo | null>(),
         },
      },
      updateSitter: {
         method: 'PATCH',
         path: '/patients/sitter',
         body: updateSitterDtoSchema,
         responses: {
            200: c.noBody(),
         },
      },
      getEmergencyContact: {
         method: 'GET',
         path: '/patients/emergency-contact',
         responses: {
            200: c.type<EmergencyContactInfo | null>(),
         },
      },
      updateEmergencyContact: {
         method: 'PATCH',
         path: '/patients/emergency-contact',
         body: updateEmergencyContactDtoSchema,
         responses: {
            200: c.noBody(),
         },
      },
      confirmDosage: {
         method: 'POST',
         path: '/patients/confirm-dosage',
         body: confirmDosageDtoSchema,
         responses: {
            200: c.noBody(),
         },
      },
      confirmShipping: {
         method: 'PATCH',
         path: '/patients/confirm-shipping',
         body: confirmShippingDtoSchema,
         responses: {
            200: c.noBody(),
         },
      },
      getShippingLog: {
         method: 'GET',
         path: '/patients/shipping-log',
         responses: {
            200: c.type<ShippingLogDto[]>(),
         },
      },
      patchPatient: {
         method: 'PATCH',
         path: '/patients',
         body: patchPatientDtoSchema,
         responses: {
            200: c.type<MyPatientResponseDto>(),
         },
      },
      getMedications: {
         method: 'GET',
         path: '/patients/medications',
         responses: {
            200: c.type<PatientMedicationsResponseDto>(),
         },
      },
      updatePatientChosenCarePlan: {
         method: 'PATCH',
         path: '/patients/chosen-care-plan',
         body: updatePatientSelectedPlanDtoSchema,
         responses: {
            200: c.noBody(),
         },
      },
      getParams: {
         method: 'GET',
         path: '/patients/params',
         responses: {
            200: c.type<PatientParamsResponseDto>(),
         },
      },
      getLastPatientPlanChoice: {
         method: 'GET',
         path: '/patients/last-plan-choice',
         responses: {
            200: c.type<PatientLastChosenCarePlanDto | null>(),
         },
      },
      getBmi: {
         method: 'GET',
         path: '/patients/bmi',
         responses: {
            200: c.type<PatientBmiResponseDto | null>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
