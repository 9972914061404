import {
   type CreatePaymentWithPatientResponseDto,
   createPaymentDevSchema,
   createPaymentSchema,
   createPaymentWithPatientDevSchema,
   createPaymentWithPatientSchema,
   updateCreditCardSchema,
   type CreateFromOrderResult,
   validatePaymentWithPatientSchema,
   type QueryApiParamsDto,
   type NmiSubscriptionProcessed,
   retryPaymentSchema,
   type RetryPaymentResponse,
   retryPaymentDevSchema,
   uuidSchema,
   type FailedPaymentDto,
   patientCustomerVaultInfoResponseSchema,
   type NmiCustomerVaultBillingProcessed,
   type FailedPaymentByIdResponseDto,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const payments = c.router(
   {
      createPayment: {
         method: 'POST',
         path: '/payments',
         body: createPaymentSchema,
         responses: {
            200: c.type<CreateFromOrderResult>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      failedPaymentDev: {
         method: 'GET',
         pathParams: z.object({
            patientId: z.string(),
            id: uuidSchema,
         }),
         path: '/payments/failed/dev/:patientId/:id',
         responses: {
            200: c.type<FailedPaymentDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      getFailedPayments: {
         method: 'GET',
         path: '/payments/failed',
         responses: {
            200: c.type<FailedPaymentDto[]>(),
         },
      },
      failedPayment: {
         method: 'GET',
         pathParams: z.object({
            id: uuidSchema,
         }),
         path: '/payments/failed/:id',
         responses: {
            200: c.type<FailedPaymentByIdResponseDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      retryPayment: {
         method: 'POST',
         path: '/payments/retry',
         body: retryPaymentSchema,
         responses: {
            200: c.type<RetryPaymentResponse>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      retryPaymentDev: {
         method: 'POST',
         path: '/payments/retry/dev',
         body: retryPaymentDevSchema,
         responses: {
            200: c.type<RetryPaymentResponse>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      createPaymentDev: {
         method: 'POST',
         path: '/payments/dev',
         body: createPaymentDevSchema,
         responses: {
            200: c.type<CreateFromOrderResult>(),
         },
      },
      createPaymentWithPatient: {
         method: 'POST',
         path: '/payments/with-patient',
         body: createPaymentWithPatientSchema,
         responses: {
            200: c.type<CreatePaymentWithPatientResponseDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      createPaymentWithPatientDev: {
         method: 'POST',
         path: '/payments/dev/with-patient',
         body: createPaymentWithPatientDevSchema,
         responses: {
            200: c.type<CreatePaymentWithPatientResponseDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      validateWithPatient: {
         method: 'POST',
         path: '/payments/validate-with-patient',
         body: validatePaymentWithPatientSchema,
         responses: {
            200: c.type<CreatePaymentWithPatientResponseDto>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      //not used by frontend
      /*nmiWebhook: {
         method: 'POST',
         path: '/payments/nmi-hook',
         body: c.noBody(),
         responses: {
            200: c.type<void>(),
         },
      },*/
      getNmiQueryResults: {
         method: 'GET',
         path: '/payments',
         query: c.type<QueryApiParamsDto>(),
         responses: {
            200: c.type<unknown>(),
         },
      },
      getSubscriptionInfo: {
         method: 'GET',
         path: '/payments/subscription/:id',
         pathParams: z.object({
            id: z.string(),
         }),
         responses: {
            200: c.type<NmiSubscriptionProcessed | null>(),
         },
      },
      updateCreditCard: {
         method: 'POST',
         path: '/payments/update',
         body: updateCreditCardSchema,
         responses: {
            200: c.noBody(),
         },
      },
      getCustomerVaultData: {
         method: 'GET',
         path: '/payments/customer-vault',
         responses: {
            200: patientCustomerVaultInfoResponseSchema.or(z.null()),
         },
      },

      getCustomerVaultBillings: {
         method: 'GET',
         path: '/payments/customer-vault-billings',
         responses: {
            200: c.type<NmiCustomerVaultBillingProcessed[] | null>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
