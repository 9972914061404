import {
   type FormsortAnswersResponse,
   createFormSortDataSchemeDto,
   formsortFormTypeSchema,
   formsortListParamsDto,
   formsortWebhookRequestSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const pathParams = z.object({
   formType: formsortFormTypeSchema,
});

const c = initContract();
export const formsort = c.router(
   {
      list: {
         method: 'GET',
         path: '/formsort',
         query: formsortListParamsDto,
         responses: {
            200: c.type<FormsortAnswersResponse[]>(),
         },
      },

      createForm: {
         method: 'POST',
         path: '/formsort',
         body: createFormSortDataSchemeDto,
         responses: {
            200: c.type<FormsortAnswersResponse | false>(),
         },
      },

      formsortWebhook: {
         method: 'POST',
         path: '/formsort/:formType',
         pathParams,
         body: formsortWebhookRequestSchema,
         responses: {
            200: c.type<{ success: true }>(),
         },
      },
      formsortSaveMultipleFormsWebhook: {
         method: 'POST',
         path: '/formsort/multiple/:formType',
         pathParams: pathParams,
         body: formsortWebhookRequestSchema,
         responses: {
            200: c.type<{ success: true }>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
