import { initContract } from '@ts-rest/core';
import {
   type ClinicianResponseDto,
   type MyPatientResponseDto,
   type NmiTransactionResponse,
} from '@innerwell/dtos';

const c = initContract();
export const account = c.router(
   {
      getPatient: {
         method: 'GET',
         path: '/account/patient',
         responses: {
            200: c.type<MyPatientResponseDto>(),
         },
      },
      getMyTherapist: {
         method: 'GET',
         path: '/account/my-therapist',
         responses: {
            200: c.type<ClinicianResponseDto | null>(),
         },
      },
      getMyClinician: {
         method: 'GET',
         path: '/account/my-clinician',
         responses: {
            200: c.type<ClinicianResponseDto | null>(),
         },
      },
      getMyEmdrTherapist: {
         method: 'GET',
         path: '/account/my-emdr-therapist',
         responses: {
            200: c.type<ClinicianResponseDto | null>(),
         },
      },
      getTransactions: {
         method: 'GET',
         path: '/account/transactions',
         responses: {
            200: c.type<NmiTransactionResponse[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
