import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import { intExtendedField } from '@innerwell/dtos';
import type { Prisma } from '@innerwell/prisma-app-generated';

const c = initContract();
export const blog = c.router(
   {
      posts: {
         method: 'GET',
         path: '/blog/posts',
         query: z.object({}),
         responses: {
            200: c.type<Partial<Prisma.PostGetPayload<undefined>[]>>(), //TODO: check this
         },
      },
      post: {
         method: 'GET',
         path: '/blog/posts/:id',
         pathParams: z.object({
            id: intExtendedField,
         }),
         responses: {
            200: c.type<Partial<Prisma.PostGetPayload<undefined>> | null>(), //TODO: check this
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
