import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const users = c.router(
   {
      getUserByEmail: {
         method: 'GET',
         path: '/users/get-user/:username',
         pathParams: z.object({
            // using string and not email validation since username is not required to be an email
            username: z.string(),
         }),
         responses: {
            200: c.type<boolean>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
