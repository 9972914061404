import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
   addCartDtoSchema,
   type InnerwellCartItem,
   type InnerwellCartTotals,
   intExtendedField,
   PaymentCode,
   setDiscountCouponDtoSchema,
   setPaymentMethodDtoSchema,
   updateCartDtoSchema,
} from '@innerwell/dtos';

const c = initContract();
export const cart = c.router(
   {
      paymentMethods: {
         method: 'GET',
         path: '/cart/:cartId/payment-options',
         pathParams: z.object({
            cartId: z.string(),
         }),
         query: z.object({
            paymentMethods: z.array(z.nativeEnum(PaymentCode)).optional(),
         }),
         responses: {
            200: c.type<InnerwellCartTotals[]>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      createCart: {
         method: 'POST',
         path: '/cart/create',
         body: c.noBody(),
         responses: {
            200: c.type<string>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      addToCart: {
         method: 'POST',
         path: '/cart/add',
         body: addCartDtoSchema,
         responses: {
            200: c.type<InnerwellCartItem>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      getCartItems: {
         method: 'GET',
         path: '/cart/:cartId/cart-items',
         pathParams: z.object({
            cartId: z.string(),
         }),
         responses: {
            200: c.type<InnerwellCartItem[]>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      removeFromCart: {
         method: 'DELETE',
         path: '/cart/:cartId/remove/:itemId',
         body: c.noBody(),
         pathParams: z.object({
            cartId: z.string(),
            itemId: intExtendedField,
         }),
         responses: {
            200: c.type<boolean>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      updateCart: {
         method: 'PUT',
         path: '/cart/update',
         body: updateCartDtoSchema,
         responses: {
            200: c.type<InnerwellCartItem>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      getPaymentMethodForCart: {
         method: 'GET',
         path: '/cart/:cartId/get-payment-method',
         pathParams: z.object({
            cartId: z.string(),
         }),
         responses: {
            200: c.type<string | null>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      setPaymentMethodForCart: {
         method: 'PUT',
         path: '/cart/:cartId/set-payment-method',
         body: setPaymentMethodDtoSchema,
         pathParams: z.object({
            cartId: z.string(),
         }),
         responses: {
            200: c.type<boolean>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      getDiscountCoupon: {
         method: 'GET',
         path: '/cart/:cartId/coupons',
         pathParams: z.object({
            cartId: z.string(),
         }),
         responses: {
            200: c.type<string | null>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      setDiscountCoupon: {
         method: 'PUT',
         path: '/cart/:cartId/coupons',
         body: setDiscountCouponDtoSchema,
         pathParams: z.object({
            cartId: z.string(),
         }),
         responses: {
            200: c.type<boolean>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
      removeDiscountCoupon: {
         method: 'DELETE',
         path: '/cart/:cartId/coupons',
         body: c.noBody(),
         pathParams: z.object({
            cartId: z.string(),
         }),
         responses: {
            200: c.type<boolean>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
