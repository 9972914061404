import { welkinWebhookRequestDtoSchema } from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const welkin = c.router(
   {
      webhook: {
         method: 'POST',
         path: '/welkin/webhooks',
         body: welkinWebhookRequestDtoSchema,
         responses: {
            200: c.noBody(),
         },
      },

      supportedTimezones: {
         method: 'GET',
         path: '/welkin/supported-timezones',
         responses: {
            200: c.type<string[]>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
