import { initContract } from '@ts-rest/core';
import {
   type CancelAppointmentByIdResponseDto,
   cancelAppointmentPaymentSchema,
   type CreateFromOrderResult,
} from '@innerwell/dtos';
import { z } from 'zod';

const c = initContract();
export const cancelAppointment = c.router(
   {
      payment: {
         method: 'POST',
         path: '/cancel-appointment/payment',
         body: cancelAppointmentPaymentSchema,
         responses: {
            200: c.type<CreateFromOrderResult>(),
         },
      },

      getCancelAppointmentById: {
         method: 'GET',
         path: '/cancel-appointment/:id',
         pathParams: z.object({
            id: z.string().uuid(),
         }),
         responses: {
            200: c.type<CancelAppointmentByIdResponseDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
