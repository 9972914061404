import {
   type WelkinPatientProgramResponseDto,
   type WelkinPatientProgramsResponseDto,
   WelkinPrograms,
   updatePatientPhaseDtoSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const programs = c.router(
   {
      getAssignedPrograms: {
         method: 'GET',
         path: '/programs',
         responses: {
            200: c.type<WelkinPatientProgramsResponseDto>(),
         },
      },
      getProgramByName: {
         method: 'GET',
         path: '/programs/:progName',
         pathParams: z.object({
            progName: z.nativeEnum(WelkinPrograms),
         }),
         responses: {
            200: c.type<WelkinPatientProgramResponseDto>(),
         },
      },
      updateProgram: {
         method: 'PATCH',
         path: '/programs/:progName',
         pathParams: z.object({
            progName: z.nativeEnum(WelkinPrograms),
         }),
         body: updatePatientPhaseDtoSchema,
         responses: {
            200: c.noBody(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
