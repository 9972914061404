import {
   type EligibleCliniciansResponseDto,
   type EncounterTypeResponseDto,
   type AvailableEncountersResponseDto,
   type AddonEncounterResponseDto,
   type RuleBasedBoundsResponseDto,
   type TimetapLocationAvailabilityResultDto,
   type TimetapMultiStaffAvailabilityByMonthResultDto,
   type TimetapMultiStaffAvailabilityResultDto,
   type TimetapNextAvailableAppointmentForLocationResultDto,
   appointmentFromBankResponseDtoSchema,
   availablePrescribingCliniciansParamsSchema,
   eligibleCliniciansParamsSchema,
   ruleBasedBoundsParamsSchema,
   timetapLocationAvailabilityParamsSchema,
   timetapMultiStaffAvailabilityByMonthParamsSchema,
   timetapMultiStaffAvailabilityParamsSchema,
   timetapNextAvailableAppointmentForLocationsParamsSchema,
   welkinEncounterTypeSchema,
   availableAddonEncountersParamsSchema,
   isEncounterReschedulableParamsDtoSchema,
   type IsEncounterReschedulableResponseDto,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const c = initContract();
export const scheduling = c.router(
   {
      getAvailableClinicians: {
         method: 'GET',
         path: '/scheduling/available-clinicians',
         query: availablePrescribingCliniciansParamsSchema,
         responses: {
            200: c.type<TimetapMultiStaffAvailabilityResultDto[]>(),
         },
      },
      getEligibleClinicians: {
         method: 'GET',
         path: '/scheduling/eligible-clinicians',
         query: eligibleCliniciansParamsSchema,
         responses: {
            200: c.type<EligibleCliniciansResponseDto[]>(),
         },
      },

      getEligibleCliniciansFromCareTeam: {
         method: 'GET',
         path: '/scheduling/eligible-clinicians-from-care-team',
         query: eligibleCliniciansParamsSchema,
         responses: {
            200: c.type<EligibleCliniciansResponseDto[]>(),
         },
      },

      getNextAvailableSlot: {
         method: 'GET',
         path: '/scheduling/availability/next-available-slot',
         query: timetapNextAvailableAppointmentForLocationsParamsSchema,
         responses: {
            200: c.type<
               TimetapNextAvailableAppointmentForLocationResultDto[]
            >(),
         },
      },
      getMultiStaffAvailability: {
         method: 'GET',
         path: '/scheduling/availability/multi-staff',
         query: timetapMultiStaffAvailabilityParamsSchema,
         responses: {
            200: c.type<TimetapMultiStaffAvailabilityResultDto[]>(),
         },
      },
      getMultiStaffAvailabilityByMonth: {
         method: 'GET',
         path: '/scheduling/availability/multi-staff-by-month',
         query: timetapMultiStaffAvailabilityByMonthParamsSchema,
         responses: {
            200: c.type<TimetapMultiStaffAvailabilityByMonthResultDto[]>(),
         },
      },

      getLocationAvailability: {
         method: 'GET',
         path: '/scheduling/availability/location',
         query: timetapLocationAvailabilityParamsSchema,
         responses: {
            200: c.type<TimetapLocationAvailabilityResultDto[]>(),
         },
      },
      getRuleBasedBounds: {
         method: 'GET',
         path: '/scheduling/rule-based-bounds',
         query: ruleBasedBoundsParamsSchema,
         responses: {
            200: c.type<RuleBasedBoundsResponseDto>(),
         },
      },

      getEncounterByName: {
         method: 'GET',
         path: '/scheduling/encounter/:encounterName',
         pathParams: z.object({
            encounterName: welkinEncounterTypeSchema,
         }),
         responses: {
            200: c.type<EncounterTypeResponseDto>(),
         },
      },

      getInsuranceEncounters: {
         method: 'GET',
         path: '/scheduling/insurance-encounters',
         responses: {
            200: c.type<AddonEncounterResponseDto[]>(),
         },
      },
      getAppointmentsFromBank: {
         method: 'GET',
         path: '/scheduling/appointment-bank',
         responses: {
            200: z.array(appointmentFromBankResponseDtoSchema),
         },
         summary:
            'Returns all program appointments from the bank including scheduling information',
      },
      getAvailableAddonEncounters: {
         method: 'GET',
         path: '/scheduling/available-addon-encounters',
         query: availableAddonEncountersParamsSchema,
         responses: {
            200: c.type<AvailableEncountersResponseDto>(),
         },
      },
      checkIfEncounterIsReschedulable: {
         method: 'GET',
         path: '/scheduling/is-encounter-reschedulable',
         query: isEncounterReschedulableParamsDtoSchema,
         responses: {
            200: c.type<IsEncounterReschedulableResponseDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
