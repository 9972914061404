import { initContract } from '@ts-rest/core';
import { z } from 'zod';
import {
   type BootstrapChatForWelkinResponse,
   chatWebhookSchema,
   ChatWebhookSourceEnum,
   type CreateGroupChannelResponse,
   createSendbirdGroupChannelSchema,
   createSendbirdUserShema,
   type CreateUserResponse,
   type GetUserResponse,
   inviteSendbirdUserToChannelSchema,
   type SendbirdUserChannelsResponse,
} from '@innerwell/dtos';

const c = initContract();
export const chat = c.router(
   {
      handleWebhook: {
         method: 'POST',
         path: '/chat/webhooks/:source',
         pathParams: z.object({
            source: z.nativeEnum(ChatWebhookSourceEnum),
         }),
         body: chatWebhookSchema,
         responses: {
            200: z.unknown(),
         },
      },
      bootstrapChatForWelkinPatient: {
         method: 'GET',
         path: '/chat/bootstrap/:patientId?',
         pathParams: z.object({
            patientId: z.string().optional(),
         }),
         responses: {
            200: c.type<BootstrapChatForWelkinResponse>(),
         },
      },
      getUserForPatient: {
         method: 'GET',
         path: '/chat/user-for-patient',
         responses: {
            200: c.type<GetUserResponse>(),
         },
      },
      getSendbirdUser: {
         method: 'GET',
         path: '/chat/users/:userId',
         pathParams: z.object({
            userId: z.string(),
         }),
         responses: {
            200: c.type<GetUserResponse>(),
         },
      },
      getSendbirdUserChannels: {
         method: 'GET',
         path: '/chat/users/:userId/channels',
         pathParams: z.object({
            userId: z.string(),
         }),
         responses: {
            200: c.type<SendbirdUserChannelsResponse>(),
         },
      },
      createSendbirdUser: {
         method: 'POST',
         path: '/chat/users',
         body: createSendbirdUserShema,
         responses: {
            200: c.type<CreateUserResponse>(),
         },
      },
      createSendbirdChannel: {
         method: 'POST',
         path: '/chat/channels',
         body: createSendbirdGroupChannelSchema,
         responses: {
            200: c.type<CreateGroupChannelResponse>(),
         },
      },
      inviteSendbirdUserToGroupChannel: {
         method: 'POST',
         path: '/chat/channels/:channelId/invite',
         pathParams: z.object({
            channelId: z.string(),
         }),
         body: inviteSendbirdUserToChannelSchema,
         responses: {
            200: c.type<CreateGroupChannelResponse>(),
         },
      },
      setLevel: {
         method: 'POST',
         path: '/chat/change-logging-level/:level',
         body: c.noBody(),
         pathParams: z.object({
            level: z.string(),
         }),
         responses: {
            200: z.void(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
