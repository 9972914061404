import { type ProgramSwitchInfoResponseDto } from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';

const c = initContract();
export const programSwitch = c.router(
   {
      getSwitchData: {
         method: 'GET',
         path: '/program-switch/insurance',
         responses: {
            200: c.type<ProgramSwitchInfoResponseDto>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
