import { initContract } from '@ts-rest/core';
import {
   trackAnonymousEventApiParamsSchema,
   trackEventParamsBeforeSignupSchema,
   trackEventParamsSchema,
   updateAnonymousCioUserSchema,
} from '@innerwell/dtos';

const c = initContract();
export const customerio = c.router(
   {
      trackAnonymous: {
         method: 'POST',
         path: '/customerio/track-anonymous',
         body: trackAnonymousEventApiParamsSchema,
         responses: {
            200: c.type<Record<string, unknown>>(),
         },
      },
      trackBeforeSignup: {
         method: 'POST',
         path: '/customerio/track-before-signup',
         body: trackEventParamsBeforeSignupSchema,
         responses: {
            200: c.type<Record<string, unknown>>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },

      updateAnonymousUser: {
         method: 'POST',
         path: '/customerio/update-anonymous-user',
         body: updateAnonymousCioUserSchema,
         responses: {
            200: c.type<Record<string, unknown>>(),
         },
         metadata: {
            dontCheckCredentials: true,
         },
      },

      track: {
         method: 'POST',
         path: '/customerio/track',
         body: trackEventParamsSchema,
         responses: {
            200: c.type<Record<string, unknown>>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
