import {
   formsortFormTypeSchema,
   formsortWebhookRequestSchema,
} from '@innerwell/dtos';
import { initContract } from '@ts-rest/core';
import { z } from 'zod';

const pathParams = z.object({
   formType: formsortFormTypeSchema,
});

const c = initContract();
export const webhook = c.router(
   {
      formsortWebhook: {
         method: 'POST',
         path: '/webhook/:formType',
         pathParams,
         body: formsortWebhookRequestSchema,
         responses: {
            200: c.type<{ success: true }>(),
         },
      },
      formsortSaveMultipleFormsWebhook: {
         method: 'POST',
         path: '/webhook/multiple/:formType',
         pathParams: pathParams,
         body: formsortWebhookRequestSchema,
         responses: {
            200: c.type<{ success: true }>(),
         },
      },
   },
   {
      strictStatusCodes: true,
   },
);
